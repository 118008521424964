/* #bg {
  position: fixed;
  top: 0;
  left: 0;

  min-width: 100%;
  min-height: 100%;
} */

html {
  background: url("../public/images/DeepBlueBackground.png");
  /*background: url("https://media.istockphoto.com/photos/christmas-lights-defocused-background-bokeh-gold-blue-picture-id613518332?k=6&m=613518332&s=612x612&w=0&h=Own5MdgJXjNhFd0YUyED1UP3mQsHeNhfML9F-DQYdYw=");*/
  /* Full height */
  min-width: 100%;
  min-height: 120%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.loginReg {
  width: 45%;
  float: right;
  margin-top: 5%;
}

.CTL {
  width: 100%;
  z-index: 1;
  margin-top: 2%;
}


/*white map only*/
.redAUS {
  position: absolute;
  top: 0;
  left: 3%;
  z-index: 0;
  width: 45%;
}

html {
  background-color: #bed7dd;
}



/* *** logo *** */
.rotate {
  display: block;
  animation: rotation 10s linear;
  animation-iteration-count: infinite;
}

.logo {
  width: 12%;
  margin-left: -35%;
  margin-top: 27%;
}

@keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
  }
}


.message-btn {
  position: absolute;
  top: 10%;
  justify-content: center;
}

.message {
  position: absolute;
  top: -15%;
}

/* *************    middle screen     ************* */
@media only screen and (max-width: 820px) {

  .loginReg {
    width: 60%;
  }

  .redAUS {
    width: 35%;
    margin-top: 9%;
  }
}

/* *************    middle screen     ************* */
@media only screen and (max-width: 415px) {

  .loginReg {
    width: 100%;
  }

  .redAUS {
    display: none;
  }
}